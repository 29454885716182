header {
  background-color: white;
}

body {
  margin: 0;
  font-family: Inter, Inter-Bold, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Inter;
  src: url('./fonts/static/Inter-Regular.svg');
}

@font-face {
  font-family: Inter-Bold;
  src: url('./fonts/static/Inter-Bold.svg');
}

@font-face {
  font-family: Inter-Medium;
  src: url('./fonts/static/Inter-Medium.svg');
}

@font-face {
  font-family: Inter-SemiBold;
  src: url('./fonts/static/Inter-SemiBold.svg');
}
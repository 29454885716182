#footer-top {
    background-color: white;
    height: 14vh;
}

#footer-bottom {
    height: 7vh;
    background-color: #F6F6F8;
}

#footer-bottom-text {
    opacity: 0.6;
}

#footer-whatsapp {
    border-radius: 100%;
    width: 10%;
}
#navbar-desktop {
    z-index: 100;
    height: 7vh;
    left: 0px !important;
    right: 0px !important;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 2rem;
    box-shadow: 0px 0px 3px 1px hsl(0, 0%, 85%);
    background-color: white;
    width: 55%;
}

.hidden-desktop{
    visibility: hidden;
    display: none !important;
}

#navbar-mobile {
    z-index: 100;
    background-color: white;
}

@media (max-width: 1050px) {
    .hidden-mobile{
        visibility: hidden;
        display: none !important;
    }
    .hidden-desktop{
        visibility: visible;
        display: flex !important;
    }
}

#navbar-text {
    color: hsl(250, 22%, 22%);
    opacity: 0.6;
    cursor: pointer;
}

#navbar-text-dropdown {
    color: black;
}

#navbar-logo {
    width: 20%;
}

#nav-dropdown-mobile {
    color: black;
}
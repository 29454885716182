#homecard {
    min-height: 75vh;
    width: 250px;
    border-radius: 1rem;
    box-shadow: 0px 0px 5px 2px rgba(47, 43, 67, 0.1);
}

#homecard-body {
    border-top: 1px solid rgba(47, 43, 67, 0.1);
    border-bottom: 1px solid rgba(47, 43, 67, 0.1);
}

#homecard-button-div {
    height: 20%;
}

#homecard-button {
    width: 100%;
    height: 70px;
    border-radius: 1.2rem;
    border: none;
}

#homecard-content {
    width: 100%;
    height: 100%;
    position: relative;
}

#homecard-header-tag {
    font-size: 12px;
    border-radius: 1rem;
}

#homecard-body-subheader {
    font-size: 12px !important;
}

#homecard-body-subheader {
    font-size: 12px !important;
}

.homecard-info-header-text-size {
    font-size: 12px;
}

.homecard-info-text-size {
    font-size: 10px;
}

.homecard-text {
    color: hsl(250, 22%, 22%, 60%);
}

.homecard-btn-normal {
    color: rgba(47, 43, 67);;
    background-color: hsl(0, 0%, 100%);
    border: 1px solid rgba(47, 43, 67, 0.1);
    box-shadow: 0px 0px 5px 2px rgba(47, 43, 67, 0.1);
    font-weight: 500;
}

.homecard-btn-normal:hover {
    background-color: hsl(0, 0%, 98%);
}

.homecard-btn-special {
    color: white;
    background-color: hsl(23, 100%, 50%);
    font-weight: 500;
}

.homecard-btn-special:hover {
    background-color: hsl(23, 100%, 48%);
}

.homecard-special {
    position: relative;
    box-shadow: 0px 0px 5px 2px hsl(154, 75%, 45%);
}

.homecard-special::before {
    content: '';
    background-image: linear-gradient(hsl(154, 75%, 45%), hsl(154, 75%, 85%));
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.6;
    border-radius: 1rem;
}
#home-a {
    position: relative;
    min-width: 100%;
    min-height: 70vh;
}

#home-a::before {
    content: '';
    background-image: linear-gradient(to right, hsl(154, 75%, 35%), hsl(174, 100%, 35%), hsl(186, 56%, 56%), hsl(198, 68%, 75%), hsl(212, 75%, 89%), hsl(229, 94%, 93%), hsl(262, 100%, 94%), hsl(302, 88%, 94%), hsl(326, 100%, 88%), hsl(347, 100%, 83%), hsl(8, 100%, 75%), hsl(23, 100%, 62%));
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.6;
}

#home-logo {
    height: 20vh;
}

#home-content-a {
    position: relative;
}

#home-b {
 min-width: 100%;
 min-height: 100vh;
}

#home-b-1 {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

#home-b-2 {
    min-height: 60vh;
}

#home-b-2-content {
    max-width: 100%;
    min-height: 100%;
}

#home-c {
    max-width: 100%;
    min-height: 50vh;
}

#home-d {
    max-width: 100%;
}

#home-d-top {
    max-width: 100%;
}

#home-d-bottom {
    max-width: 100%;
}

#language-selector {
    width: 200px;
}
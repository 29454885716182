* {
  margin: 0;
  padding: 0;
  font-family: Inter-Bold, sans-serif;
  color: hsl(250, 22%, 0%);
}

@font-face {
  font-family: Inter;
  src: url('./fonts/static/Inter-Regular.svg');
}

@font-face {
  font-family: Inter-Bold;
  src: url('./fonts/static/Inter-Bold.svg');
}

@font-face {
  font-family: Inter-Medium;
  src: url('./fonts/static/Inter-Medium.svg');
}

@font-face {
  font-family: Inter-SemiBold;
  src: url('./fonts/static/Inter-SemiBold.svg');
}

.App {
  background-color: white;
}

.btn-ternary {
  background-color: hsl(23, 100%, 50%);
  color: white;
  border: none;
  height: 5vh;
  border-radius: 2rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  overflow: none;
  white-space: nowrap;
}

.btn-ternary:hover {
  background-color: hsl(23, 100%, 49%);
}

/* text styles */

.faded {
  color: hsl(250, 22%, 22%, 60%);
  font-weight: 500;
}

.faded-subheader{
  color: #9CA3AF;
}


/* Scroll Bar Settings */

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1.2rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Scroll Bar Settings End */